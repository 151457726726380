@import './typography';

@font-face {
  font-family: $--font-name;
  src: url(../fonts/Inter-Regular.woff);
}

@font-face {
  font-family: $--font-name-bold;
  src: url(../fonts/Inter-SemiBold.woff);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  @include text-base;
}

html,
body,
#scrollableDiv {
  scroll-behavior: smooth !important;
}

.ant-layout-content {
  padding: 25px !important;
}

a {
  text-decoration: none;
}

strong {
  font-family: $--font-name-bold;
}

.page-container {
  width: 100%;
  margin: auto;
}

.container {
  width: 100%;
  max-width: $--width-container;
  margin: auto;
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #f5f5f5;
}

.ant-table tbody > tr.ant-table-row:hover > td {
  background-color: #e1ebff33 !important;
}

.page {
  &-title {
    @include text-bold($--font-size-medium);
    color: $--color-text-bold;
    margin: 0;
  }

  &__header-line {
    padding-bottom: 16px;
    border-bottom: 1px solid $--color-line;
    margin-bottom: 32px;
    min-height: 65px;
    align-items: center;
  }
}

.flex {
  display: flex;

  &.justify-space {
    justify-content: space-between;
  }
}

.ant-btn {
  @include text-bold($--font-size-base);

  &-default {
    background-color: #f0f2f5 !important;
    color: $--color-text-bold !important;
  }
}

.ant-select-selection-placeholder {
  @include text-base;
  color: #828894;
  opacity: 1 !important;
}

.form-content {
  max-width: 524px;
  padding: 15px;
  margin: auto;

  &__group-name {
    @include text-bold($--font-size-base);
    color: $--color-text-bold;
  }

  &__footer {
    margin-top: 24px;
    text-align: right;

    .ant-btn + .ant-btn {
      margin-left: 20px;
    }
  }

  &__label {
    @include text-base();
    color: $--color-text-secondary;
  }

  .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-form-item-label {
    > label {
      @include text-base();
      color: $--color-text-secondary;
      height: auto;

      &::before {
        display: none;
      }
    }

    > label.ant-form-item-required::before {
      display: none !important;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-select-multiple .ant-select-selection-item {
    background-color: $--color-text-secondary;
    color: #ffffff;

    svg {
      fill: #fff;
    }
  }
}

.table-container {
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
    0px 1px 1px -1px rgba(130, 136, 148, 0.48);
  border-radius: 4px;

  thead > tr > th {
    background-color: #fff;
    font-weight: bolder !important;
    font-family: $--font-name-bold;
  }
}

.indigo-search-header {
  .search-btn {
    width: 120px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
      0px 1px 1px -1px rgba(130, 136, 148, 0.48);
    border-radius: 4px;
    background-color: #fff;
    color: #538dff;
    font-weight: bold;
  }

  .search-input {
    // width: 494px;
    height: 48px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
      0px 4px 6px -2px rgba(130, 136, 148, 0.32);
    border-radius: 4px;

    > input {
      background-color: #fff;
    }
  }
}

.btn-sample-1 {
  color: #538dff !important;
  width: 120px;
  background: rgb(255, 255, 255) !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
    0px 1px 1px -1px rgba(130, 136, 148, 0.48);
  border-radius: 4px;

  &:disabled {
    background: rgb(255, 255, 255);
    border: none;
    color: #828894 !important;
  }
}

.indigo-filter-header {
  .ant-form-item-label {
    > label {
      height: auto;
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .applied-btn {
    @extend .btn-sample-1;
  }

  .ant-select-multiple .ant-select-selection-item {
    background-color: $--color-text-secondary;
    color: #ffffff;

    svg {
      fill: #fff;
    }
  }
}

.indigo-divider-header {
  margin: 12px 0px !important;
}

.indigo-hide {
  display: none;
}

.indigo-tab {
  .ant-tabs-tab-active {
    color: #538dff !important;
    font-weight: bold !important;
  }

  .ant-tabs-tab {
    padding-left: 15px !important;
    padding-right: 15px !important;
    font-weight: bold !important;
  }

  .ant-tabs-ink-bar {
    background-color: #538dff;
  }
}

.ant-modal-title {
  font-size: 18px !important;
  font-family: $--font-name-bold !important;
  font-weight: bold !important;
}

.ant-modal-close-x {
  font-size: 18px !important;
}

// .fixed-width-btn {
//   width: 97px !important;
// }
.ant-btn {
  height: 48px !important;
  min-width: 97px !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #2b368b !important;

  > .ant-steps-icon {
    color: #fff !important;
  }
}

.ant-steps-item-tail::after {
  height: 3px !important;
  background-color: #2b368b !important;
}

.ant-steps-item-process .ant-steps-item-title {
  font-family: $--font-name-bold !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

.ant-steps-item-finish .ant-steps-item-title {
  @extend .text-ink;
}

.ant-steps-item-finish .ant-steps-item-title {
  font-family: $--font-name-bold !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

.text-bold {
  font-weight: bold;
  font-family: $--font-name-bold;
}

.btn-underline {
  text-decoration: underline !important;

  span {
    text-decoration: underline !important;
  }
}

.text-clickable {
  cursor: pointer !important;

  &[disbaled] {
    cursor: default !important;
  }
}

.text-underline {
  text-decoration: underline !important;
}

.field-underline {
  &:hover {
    @extend .text-clickable;
    @extend .text-underline;
  }
}

.btn-default {
  border: none;
  background-color: transparent;
  outline: none;

  &[disabled] {
    cursor: default;
  }
}

.clickable {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  font-family: $--font-name-bold;
  @extend .text-underline;
  @extend .text-clickable;

  &[disabled] {
    cursor: default !important;
  }
}

.relative {
  position: relative;
}

.text {
  @include text-base;
  color: $--color-text-bold;

  &--bold {
    @include text-bold($--font-size-base);
    color: $--color-text-bold;
  }

  &--large {
    @include text-bold($--font-size-medium);
    color: $--color-text-bold;
  }

  &--label {
    @include text-base;
    color: $--color-text-secondary;
  }

  &--capitalize {
    text-transform: capitalize;
  }
}

.text-large {
  @include text-bold($--font-size-large);
  color: $--color-text-bold;
}

.text-danger {
  color: #f54f64 !important;
}

.text-info {
  color: #538dff !important;
}

.text-primary {
  color: #2b368b !important;
}

.text-success {
  color: #65db75 !important;
}

.text-warning {
  color: #ffa629 !important;
}

.text-purple {
  color: #6d48e5 !important;
}

.text-ink {
  color: #000 !important;
}

.text-secondary {
  color: #828894 !important;
}

.text-white {
  color: #fff !important;
}

.table-clickable {
  tbody > tr {
    @extend .text-clickable;
  }
}

.create-student-form {
  .form-group-title {
    @include text-bold($--font-size-medium);
    line-height: 20px;
  }

  .form-group-content {
    margin-top: 20px;
  }

  .ant-form-item-label {
    > label {
      height: auto;
    }

    > label.ant-form-item-required::before {
      display: none;
    }
  }

  &__link {
    @include text-bold($--font-size-base);
    text-decoration: underline;
  }

  .ant-btn-link {
    padding: 0;
    height: auto;

    > span {
      text-decoration: underline;
    }
  }

  &.pa {
    .ant-btn-link {
      height: 48px;
    }
  }
}

.form-group-title {
  font-family: Inter-SemiBold;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.form-group-border {
  padding: 20px;
  border: 1px solid rgb(216, 220, 230);

  + .form-group-border {
    margin-top: 20px;
  }
}

.button-black-text {
  color: #000 !important;
}

.btn-danger {
  background: #f54f64 !important;
  border: none !important;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  width: 100%;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-small {
  font-size: 12px !important;
}

.text-medium {
  font-size: 18px !important;
}

.text-xl {
  font-size: 32px !important;
}

.text-2xl {
  font-size: 48px !important;
}

.bg-white {
  background: #fff !important;
}

.ant-tag {
  &.idg-tag {
    &--primary {
      color: #fff;
      background-color: $--color-tag;
      border-radius: 18px;
      @include text-base($--font-size-small);
      height: 24px;
      line-height: 24px;
      border-width: 0;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.input--noborder.ant-input,
.input--noborder {
  border: 2px solid transparent !important;

  &:hover,
  &:focus {
    border: 2px solid rgb(83, 141, 255) !important;
  }
}

.reset-filter-btn {
  .reset-filter-icon {
    margin-right: 5px;
  }
}

.pill-wrapper-flex {
  display: flex;
  justify-content: flex-end;
}

@media print {
  .noPrint {
    visibility: hidden;
  }
}

.ql-editor {
  min-height: 100px !important;
}

.editor-btns {
  .ant-btn {
    color: rgba(0, 0, 0, 0.65) !important;
    background-color: #f0f2f5 !important;

    &.ant-btn-primary {
      color: #fff !important;
      background-color: #2b368b !important;
      border-color: #2b368b !important;
    }
  }
}

.fadein-effect {
  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s;
  /* Firefox < 16 */
  -ms-animation: fadein 1s;
  /* Internet Explorer */
  -o-animation: fadein 1s;
  /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.currency-input {
  -webkit-appearance: none;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;

  &:hover {
    border-color: #c0c4cc;
  }

  &:focus {
    outline: none;
  }

  &.currency-input-disble {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed;
  }
}

.student-option-item {
  padding-left: 40px;
  height: 38px;
  line-height: 38px;
}

.student-card {
  padding: 16px;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px dashed rgb(216, 220, 230);
  width: 380px;

  .label,
  .text {
    margin: 0;
  }

  .flex {
    align-items: center;

    + .flex {
      margin-top: 16px;
    }
  }
}

.siblings-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-top: -20px;

  .student-card {
    margin-left: 20px;
    margin-top: 20px;
  }
}

.credit-note {
  &__status {
    margin: 0;
    @include text-small;
    border-radius: 12px;
    text-align: center;
    padding: 3px 12px;
    display: inline-block;

    &.awaiting_sale_action {
      color: #ffffff;
      background-color: #fa73c4;
    }

    &.awaiting_front_desk_action {
      color: #ffffff;
      background-color: rgb(173, 153, 240);
    }

    &.awaiting_finance_approval {
      color: #ffffff;
      background-color: #5064ff;
    }

    &.awaiting_finance_action {
      color: #ffffff;
      background-color: #fa73c4;
    }

    &.rejected_by_sale,
    &.rejected_by_finance {
      color: #ffffff;
      background-color: rgb(245, 79, 100);
    }

    &.used {
      color: #ffffff;
      background-color: #ffa629;
    }

    &.issued {
      color: #ffffff;
      background-color: rgb(83, 141, 255);
    }

    &.voided {
      color: #ffffff;
      background-color: rgb(130, 136, 148);
    }

    &.refunded {
      color: #ffffff;
      background-color: #538dff;
    }
  }

  &__general-info {
    margin-bottom: 40px;

    .text--label {
      margin-bottom: 6px;
    }
  }
}

.lesson-form-session {
  border: 1px solid $--color-line;
  padding: 20px;
  position: relative;

  + .lesson-form-session {
    margin-top: 20px;
  }

  &__row {
    margin-top: 20px;
  }

  &__name {
    margin: 0;
    @include text-bold($--font-size-base);
    color: $--color-text-bold;
  }

  &__label {
    @include text-small;
    color: $--color-text-secondary;
  }

  &__btn-delete {
    &.ant-btn {
      position: absolute;
      top: 8px;
      right: 20px;
      padding: 0;
      height: 36px;
      line-height: 36px;
      @include text-bold($--font-size-base);
      z-index: 2;
    }
  }

  .flex {
    display: flex;

    &-space-between {
      align-items: center;
      justify-content: space-between;
    }
  }
}

.secondary-header {
  font-size: 18px !important;
  font-weight: bold;
}

.ant-form-item-required::before {
  display: none !important;
}

.ant-btn[disabled] {
  cursor: default !important;
}

.center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-capitalized {
  text-transform: capitalize;
}

.font-bold {
  font-weight: bolder;
}

.ml-auto {
  margin-left: auto;
}

.action-btn-menu {
  padding: 0;

  li {
    padding: 10px 25px;
    color: black;
    transition: 0.3s ease;

    &:hover {
      background-color: #c0c3cc;
    }
  }
}

.action-btn {
  > svg {
    margin-left: 0.5rem;
  }
}

.check-circle {
  background-color: #68d977;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-check-cont {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.paynow-img {
  height: auto;
  width: 150px;
  margin-bottom: 1rem;
  margin-top: 5px;
}

.creat-student-family-info-cont {
  padding-left: 15px;
  padding-right: 15px;
}

.create-studen-info-cont {
  margin: 15px 20px;
}

.text-black {
  color: black;
}

.nowrap {
  white-space: nowrap !important;
}

@media (min-width: 600px) {
  .form-content {
    padding: 0;
  }

  .create-studen-info-cont {
    margin: 40px 60px;
  }

  .creat-student-family-info-cont {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.default-mt-create-student {
  margin-top: 50px;
}

@media (min-width: 600px) {
  .default-mt-create-student {
    margin-top: 60px;
  }
}

.discount-input {
  &:disabled {
    background-color: #fafbfd;
    border-color: #fafbfd;
  }
}

.btn-disabled {
  pointer-events: none;
  color: #828894 !important;
  cursor: not-allowed !important;
}

.credit-note-text {
  background-color: #d8dce6;
  padding: 12px;
  border-radius: 4px;
  display: inline-block;
  min-width: 120px;
}
